import axios from 'axios';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';
import { createToast } from 'mosha-vue-toastify'
import i18n from '@/i18n';

const baseURL = process.env.NODE_ENV === 'production' 
    ? process.env.VUE_APP_API_URL_PROD 
    : process.env.VUE_APP_API_URL_DEV;

const instance = axios.create({
    baseURL,
    timeout: 10000
});

// Request interceptor
instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor
instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response?.status === 401) {
        const authStore = useAuthStore();
        authStore.logout();

        createToast(i18n.global.t('errors.sessionNotRecovered'), {
            type: 'danger',
            position: 'top-right',
            timeout: 5000,
            showIcon: true,
        });

        const currentRoute = router.currentRoute.value;
        if (currentRoute.path !== `/${currentRoute.params.lang}`) {
            router.push(`/${currentRoute.params.lang}`);
        }
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }
    return Promise.reject(error);
});

export default instance;