import { defineStore } from 'pinia';

export const useCookieStore = defineStore('cookie', {
  state: () => ({
    accepted: localStorage.getItem('cookiesAccepted') === 'true',
  }),
  actions: {
    acceptCookies() {
      this.accepted = true;
      localStorage.setItem('cookiesAccepted', 'true');
    },
    rejectCookies() {
      this.accepted = false;
      localStorage.setItem('cookiesAccepted', 'false');
    },
  }
});