import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import axios from '@/plugins/axios'

const NICKNAME_PARTS = {
    adjectives: [
        'Curious', 'Bright', 'Swift', 'Clever', 'Dynamic', 'Noble', 'Gentle', 'Wise', 
        'Brave', 'Loyal', 'Daring', 'Bold', 'Mysterious', 'Energetic', 'Fearless', 'Radiant',
        'Charming', 'Majestic', 'Serene', 'Fierce', 'Agile', 'Playful', 'Resilient', 'Elegant'
    ],
    animals: [
        'Dolphin', 'Eagle', 'Fox', 'Owl', 'Panda', 'Tiger', 'Wolf', 'Lion', 
        'Hawk', 'Cheetah', 'Leopard', 'Jaguar', 'Falcon', 'Raven', 'Phoenix', 'Dragon',
        'Koala', 'Otter', 'Bear', 'Bison', 'Cobra', 'Lynx', 'Panther', 'Shark'
    ],
    colors: [
        'Azure', 'Coral', 'Jade', 'Ruby', 'Silver', 'Gold', 'Crystal', 'Violet', 
        'Emerald', 'Sapphire', 'Amethyst', 'Citrine', 'Obsidian', 'Pearl', 'Bronze', 'Ivory',
        'Topaz', 'Turquoise', 'Scarlet', 'Indigo', 'Lavender', 'Onyx', 'Midnight', 'Blush'
    ]
};
export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: JSON.parse(localStorage.getItem("user")) || null,
        token: localStorage.getItem('token') || null,
        visitor: localStorage.getItem('visitor') || null,
        lastGeneratedNickname: null,
        favorites: JSON.parse(localStorage.getItem('favorites')) || [],
        privateChats: JSON.parse(localStorage.getItem('privateChats')) || [],
    }),

    getters: {
        isAuthenticated: (state) => !!state.user && !!state.token,
        isVisitor: (state) => !!state.visitor && !state.user, // Verifica si es visitante
        currentNickname: (state) => state.visitor?.nickname || null,
        // Nuevos getters para favoritos
        getFavorites: (state) => state.favorites,
        isFavorite: (state) => (agentId) => {
            return state.favorites.some(fav => {
                // Verificar si el objeto tiene la propiedad agent_id
                return fav && (fav.agent_id === agentId || fav === agentId);
            });
        },
        // Obtener todos los chats privados
        getPrivateChats: (state) => state.privateChats,
        // Comprobar si existe un chat privado con un agente específico
        hasPrivateChat: (state) => (agentId) => {
            return state.privateChats.some(chat => chat.agent_id === agentId);
        },
        // Obtener el chat privado por ID de agente
        getPrivateChatByAgentId: (state) => (agentId) => {
            return state.privateChats.find(chat => chat.agent_id === agentId);
        }
    },

    actions: {
        checkAuth() {
            try {
                if (this.user && this.token) return true;

                const storedUser = localStorage.getItem('user');
                const storedToken = localStorage.getItem('token');

                if (storedUser && storedToken) {
                    this.user = JSON.parse(storedUser);
                    this.token = storedToken;
                    return true;
                }

                return false;
            } catch (error) {
                console.error('Error verificando autenticación:', error);
                return false;
            }
        },

        // Modificar login
        login(userData, token) {
            try {
                this.user = userData;
                this.token = token;
                localStorage.removeItem('visitor');
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('token', token);

                // Actualizar favoritos si vienen en los datos del usuario
                if (userData.favorite_agents && Array.isArray(userData.favorite_agents)) {
                    this.favorites = userData.favorite_agents;
                    localStorage.setItem('favorites', JSON.stringify(this.favorites));
                }
                // Actualizar chats privados si vienen en los datos del usuario
                if (userData.private_chat_sessions && Array.isArray(userData.private_chat_sessions)) {
                    this.privateChats = userData.private_chat_sessions;
                    localStorage.setItem('privateChats', JSON.stringify(this.privateChats));
                }
            } catch (error) {
                console.error('Error durante el login:', error);
            }
        },

        addPrivateChat(chatSession) {
            // Verificar si el chat ya existe
            const existingChat = this.privateChats.find(chat =>
                chat.agent_id === chatSession.agent_id && chat.user_id === chatSession.user_id
            );

            if (!existingChat) {
                this.privateChats.push(chatSession);
                localStorage.setItem('privateChats', JSON.stringify(this.privateChats));
            }
            return chatSession;
        },

        // Modificar addFavorite
        async addFavorite(agentId) {
            try {
                const response = await axios.post('/add-favorite-agent', {
                    agent_id: agentId,
                    user_id: this.user.id
                });

                if (response.data.success) {
                    const favorite = response.data.favorite || { agent_id: agentId };
                    this.favorites.push(favorite);
                    // Guardar en localStorage
                    localStorage.setItem('favorites', JSON.stringify(this.favorites));
                    return true;
                }
                return false;
            } catch (error) {
                console.error('Error al agregar favorito:', error);
                return false;
            }
        },

        // Modificar removeFavorite
        async removeFavorite(agentId) {
            try {
                const response = await axios.post('/remove-favorite-agent', {
                    agent_id: agentId,
                    user_id: this.user.id
                });

                if (response.data.success) {
                    this.favorites = this.favorites.filter(fav => 
                        fav && (fav.agent_id !== agentId && fav !== agentId)
                    );
                    // Guardar en localStorage
                    localStorage.setItem('favorites', JSON.stringify(this.favorites));
                    return true;
                }
                return false;
            } catch (error) {
                console.error('Error al eliminar favorito:', error);
                return false;
            }
        },
        // Modificar clearFavorites
        clearFavorites() {
            this.favorites = [];
            localStorage.removeItem('favorites');
        },

        // Modificar logout para limpiar favoritos
        logout() {
            try {
                this.user = null;
                this.token = null;
                this.clearFavorites();
                this.privateChats = []; // Limpiar chats privados
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('privateChats'); // Eliminar chats privados del localStorage
                this.setVisitor();
            } catch (error) {
                console.error('Error al cerrar sesión:', error);
            }
        },

        generateNickname() {
            const adjective = NICKNAME_PARTS.adjectives[Math.floor(Math.random() * NICKNAME_PARTS.adjectives.length)];
            const animal = NICKNAME_PARTS.animals[Math.floor(Math.random() * NICKNAME_PARTS.animals.length)];
            const color = NICKNAME_PARTS.colors[Math.floor(Math.random() * NICKNAME_PARTS.colors.length)];
            const number = Math.floor(Math.random() * 90 + 10); // Número de dos dígitos (10-99)

            return `${color}${adjective}${animal}${number}`;
        },
        setVisitor() {
            // Primero intentamos recuperar el visitante del localStorage
            try {
                const storedVisitor = localStorage.getItem('visitor');
                if (storedVisitor) {
                    this.visitor = JSON.parse(storedVisitor);
                    // Actualizamos solo lastActive
                    this.visitor.lastActive = new Date().toISOString();
                    localStorage.setItem('visitor', JSON.stringify(this.visitor));
                    return this.visitor;
                }
            } catch (error) {
                console.warn('Error al recuperar visitante del localStorage:', error);
            }

            // Si no hay visitante en localStorage, creamos uno nuevo
            if (!this.visitor) {
                this.visitor = {
                    id: uuidv4(),
                    nickname: this.generateNickname(),
                    createdAt: new Date().toISOString(),
                    lastActive: new Date().toISOString()
                };

                try {
                    localStorage.setItem('visitor', JSON.stringify(this.visitor));
                } catch (error) {
                    console.warn('Error al guardar visitante en localStorage:', error);
                }
            }

            return this.visitor;
        }
    },
});