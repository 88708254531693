import { createRouter, createWebHistory } from 'vue-router';
import i18n from '../i18n/index.js';
import { useAuthStore  } from "@/stores/auth";

const DefaultLayout = () => import('../layouts/DefaultLayout.vue');
const Home = () => import('../components/HomeDefault.vue');
//const Categories = () => import('../components/Categories.vue');
const SubCategories = () => import('../components/SubCategories.vue');
const AllTags = () => import('../components/AllTags.vue');
const NotFound = () => import('../components/NotFound.vue');


const ChatGirlfriend = () => import('../components/chats/ChatComponent.vue');
const myChatSessions = () => import('../components/chatsSessions/ChatsSessions.vue');
const userProfile = () => import('../components/user_profile/userProfile.vue');
 

const routes = [
    {
        path: '/',
        redirect: () => {
            const lang = localStorage.getItem('lang') || navigator.language.split('-')[0] || 'en';
            return `/${lang}`;
        }
    },
    {
        path: '/:lang',
        component: DefaultLayout,
        beforeEnter: (to, from, next) => {
            const supportedLanguages = ['es', 'en', 'fr', 'de', 'it', 'pt'];
            if (!supportedLanguages.includes(to.params.lang)) {
                return next('/en'); // Idioma por defecto
            }
            return next();
        },
        children: [
            {
                path: '/:lang/:pathMatch(.*)*',
                name: 'NotFound',
                component: NotFound
            },
            {
                path: '',
                name: 'Home',
                component: Home,
            },
            {
                path: ':category/:subcategory',
                name: 'SubCategories',
                component: SubCategories,
            },
            {
                path: 'tag/:tag',
                name: 'AllTags',
                component: AllTags,
            },
            {
                path: 'chat/:girlfriend',
                name: 'ChatGirlfriend',
                component: ChatGirlfriend,
                
            },
            {
                path: 'chats/:chatId?/',
                name: 'myChatSessions',
                component: myChatSessions,
                meta: { requiresAuth: true } // Ruta protegida
            },


            //perfil del usuario 
            {
                path: 'profile',
                name: 'userProfile',
                component: userProfile,
                meta: { requiresAuth: true } // Ruta protegida
            }





        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach(async (to, from, next) => {
    const auth = useAuthStore();

    if (to.params.lang) {
        localStorage.setItem('lang', to.params.lang);
        i18n.global.locale = to.params.lang;
    }

    // Listado de rutas que requieren autenticación
    const protectedRoutes = [
        "/chats",
        "/user",
    ];

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth) || protectedRoutes.some(route => to.path.startsWith(route));

    if (requiresAuth && !auth.checkAuth()) {
        return next({ path: '/' });
    }

    next();
});

export default router;