import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: '#1976D2',
                    'primary-lighten1': '#2196F3',
                    secondary: '#424242',
                    accent: '#82B1FF',
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FFC107',
                    background: '#F5F5F5',
                }
            },
            dark: {
                colors: {
                    primary: '#2196F3',
                    'primary-lighten1': '#42A5F5',
                    background: '#121212'
                }
            }
        }
    },
    defaults: {
        VList: {
            nav: true,
        },
        VNavigationDrawer: {
            width: 256,
        },
        VBtn: {
            variant: 'text',
            style: 'text-transform: none;'
        }
    },
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904,
        },
    }
});