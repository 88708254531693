import { createI18n } from 'vue-i18n';
import messages from './messages';

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || navigator.language.split('-')[0] || 'en',
    fallbackLocale: 'en',
    messages
});

export default i18n;