const messages = {
    en: {
        cookies: {
            cookieTitle:"Adults Only: We Personalize Your Pleasure",
            cookieWarning:"We use cookies and similar technologies, including third-party cookies, to provide you with a more immersive and personalized experience. These help us optimize the site, analyze traffic, tailor content to your preferences, and offer you more fluid interactions. By continuing to browse, you agree to our use of cookies. For more information, see our ",
            cookiePolicy: "Cookie Policy",
            accept: "Accept Cookies",
            manage: "Manage or Reject Cookies",
            rejectAll: "Reject All",
            acceptAll: "Accept All",
            close: "Close",
            manageTitle: "Manage your Cookie Preferences",
            manageText: "Select your cookie preferences.",
            adultWarning: "Virtual Girlfriend contains adult content. By using the website, you acknowledge that you are over 18 years old."
        },
        race: {
            title1: "Latina",
            title2: "White",
            title3: "Black",
            title4: "Asian",
            title5: "Arabic",
            title6: "Indian",
            description1: "Latinas are pure passion, with irresistible curves and an inner fire you won’t be able to extinguish.",
            description2: "White girls will captivate you with their elegance, angelic beauty, and an irresistible touch of mischief.",
            description3: "Black women exude sensuality, energy, and a fiery rhythm that will make you lose control.",
            description4: "Asian women combine sweetness and mystery, with an exotic sensuality that will leave you breathless.",
            description5: "Arab women are a perfect mix of charm and mysticism, ready to unleash your deepest desires.",
            description6: "Indian women have a mesmerizing beauty and an enveloping seduction that will trap you in their spell."
        },
        body: {
            title1: "Slim",
            title2: "Athletic",
            title3: "Curvy",
            title4: "Chubby",
            description1: "Slim women are pure charm and sensuality, with slender bodies that invite you to explore every inch.",
            description2: "Athletic women have the perfect mix of strength and femininity, ready to take you to a game with no limits.",
            description3: "Curvy women know how to use their bodies to seduce you, with moves that will leave you breathless.",
            description4: "Chubby women are sweetness and fire in one package, with an enveloping sensuality that will make you crave more."
        },
        height: {
            description1: "Petite but irresistible, with a sensuality that defies their height and a playful attitude that you'll love.",
            description2: "The perfect height to enjoy every moment, with a balance of elegance and passion that will captivate you.",
            description3: "Tall and seductive, with breathtaking legs and a presence that will make you crave every moment with them.",
            description4: "Imposing and magnetic, with a dominant sensuality and a body designed for pleasure."
        },
        hair: {
            title1: "Black",
            title2: "Brown",
            title3: "Blonde",
            title4: "Redhead",
            title5: "Other color",
            description1: "Black hair as dark as the night, deep and mysterious, ready to wrap you in its irresistible charm.",
            description2: "Brunettes combine sweetness and mischief, with a natural sensuality that will captivate you instantly.",
            description3: "Blondes are the center of attention, with a spark of boldness that makes them irresistible.",
            description4: "Redheads burn with passion, with an inner fire that will make you lose your mind in every encounter.",
            description5: "Daring and unique, with an unconventional look that reflects an intense and unmatched personality."
        },
        age: {
            title1: "Mature",
            description1: "Curiosity and passion merge into an adventure full of discoveries.",
            description2: "Schoolgirls, university students, and daring wives ready to fulfill your wildest fantasies.",
            description3: "MILFs aged 30 to 40 have the confidence and experience to push you to the edge of pleasure.",
            description4: "Over 40, seductive and uninhibited, ready to show you everything they've learned."
        },        
        alert: {
            title: 'Virtual Girlfriends Online',
            description: 'Chat with virtual girlfriends live on NovaVirtual.com. Intimate and personalized connections with the most popular girls. Chat now!'
        },
        agent: {
            status: 'ONLINE',
            addFavorite: 'Add to favorites',
            removeFavorite: 'Remove from favorites',
            privateChat: "LET'S TALK IN PRIVATE",
            viewGallery: "View gallery",
            gallery: {
                noImages: "No images found.",
                image: "Gallery image"
            },
            sendGift: "Send gift",
            buyCredits: "Buy credits"
        },
        home: {
            h1: 'Connect With Your <span class="highlight-text">Virtual Girlfriend</span>',
            description: 'Enjoy unique moments with your ideal companion. Chat, connect, and live<br>unforgettable experiences from anywhere, keeping the excitement and<br>company always with you.',
        },
        header: {
            login: 'LOGIN',
            register: 'REGISTER',
            access: 'ACCESS',
            searchLanguage: 'Search language',
            explore: 'EXPLORE',
            chats: 'MESSAGES',
            logout: 'Sign out',
            messages: 'Messages',
            profile: 'Profile',
            myFavorites: 'My Favorites',
        },
        auth: {
            register: {
                title: 'Create an account',
                description: 'Enter your email and password to create\na new account.',
                email: 'Email',
                password: 'Password',
                confirmPassword: 'Confirm password',
                button: 'CREATE ACCOUNT',
                haveAccount: 'Already have an account?',
                loginHere: 'Login here!',
                validation: {
                    emailRequired: 'Email is required',
                    emailValid: 'Email must be valid',
                    passwordRequired: 'Password is required',
                    passwordLength: 'Password must be at least 8 characters',
                    confirmRequired: 'Confirm your password',
                    passwordsMatch: 'Passwords do not match',
                    formErrors: 'Please correct the form errors'
                },
                success: 'Registration successful!',
                errors: {
                    'email-taken': 'This email is already registered',
                    'password-length': 'Password must be at least 8 characters',
                    'password-confirmed': 'Passwords do not match'
                }
            },
            login: {
                title: 'Sign in',
                description: 'Enter your email and password to access.',
                email: 'Email',
                password: 'Password',
                forgotPassword: 'Forgot password?',
                button: 'SIGN IN',
                noAccount: 'Don\'t have an account?',
                registerHere: 'Create your account here!',
                validation: {
                    emailRequired: 'Email is required',
                    emailValid: 'Email must be valid',
                    passwordRequired: 'Password is required',
                    formErrors: 'Please correct the form errors'
                },
                success: 'Login successful!',
                errors: {
                    'invalid-credentials': 'Invalid credentials',
                    'email-not-found': 'User not found',
                    'invalid-password': 'Incorrect password',
                    'account-disabled': 'Account disabled'
                }
            },
            resetPassword: {
                title: 'Reset Password',
                description: 'Enter your email address to receive instructions.',
                email: 'Email',
                button: 'SEND INSTRUCTIONS',
                rememberPassword: 'Remember your password?',
                backToLogin: 'Back to login!',
                validation: {
                    emailRequired: 'Email is required',
                    emailValid: 'Email must be valid',
                    formErrors: 'Please check the form fields'
                },
                success: 'Instructions sent to your email',
                errors: {
                    'email-not-found': 'This email address is not registered.',
                    'too-many-requests': 'Too many password reset requests. Please try again later.',
                    'cannot-send-link': 'Could not send the password reset link. Check your email or try later.',
                    'server-error': 'An unexpected error occurred. Please try again later.'
                }
            },
            changePassword: {
                title: 'Create New Password',
                description: 'Enter a new password for your account',
                password: 'New Password',
                confirmPassword: 'Confirm New Password',
                button: 'CHANGE PASSWORD',
                validation: {
                    passwordRequired: 'Password is required',
                    passwordLength: 'Password must be at least 8 characters',
                    confirmRequired: 'Please confirm your password',
                    passwordsMatch: 'Passwords do not match',
                    formErrors: 'Please check the form fields'
                },
                errors: {
                    'invalid-token': 'The password reset link is invalid. Please request a new link.',
                    'reset-failed': 'Could not reset password. Please try again.',
                    'server-error': 'An unexpected error occurred. Please try again later.'
                },
                success: 'Password successfully changed. Redirecting to login...'
            }
        },
        sidebar: {
            menu: {
                explore: 'EXPLORE',
                categories: 'Categories',
                chats: 'MESSAGES'
            },
            footer: {
                terms: 'Terms and conditions',
                rights: '© 2024 All rights reserved'
            },
            showMore: 'Show More',
            showLess: 'Show Less',
            showMoreCount: 'Show More ({count})',
            popularTags: 'Tags',
            categories: 'Categories',
            languages: 'Languages',
            details_relationship: 'Relationship details',
            close_profile: "Close profile",
            show_profile: "Show profile"

        },
        error: {
            404: {
                title: 'Page not found',
                description: 'The page you are looking for does not exist or has been moved.',
                button: 'Back to Home'
            }
        },
        subcategories: {
            noResults: 'No results found',
            tryAnother: 'Try another category or subcategory'
        },
        tags: {
            noResults: 'No agents found with this tag',
            tryAnother: 'Try another tag'
        },
        discover: {
            moreAgents: "Discover more virtual girlfriends here"
        },
        chat: {
            connectedAs: "Connected as:",
            publicMessage: "Public message...",
            waitMessage: "Please wait {seconds} seconds to send another message.",
            messageRemoved: "Message will be removed in {seconds} seconds"
        },
        profileMenu: {
            profile: 'Profile',
            billding: 'Billing'
        },
        profile: {
            uploadPhoto: "Upload Photo",
            sizeLimit: "The maximum upload size is 1 MB.",
            editProfile: "Edit Profile",
            username: "Username",
            birthdate: "Birthdate",
            phoneIndicator: "Phone Indicator",
            phoneNumber: "Phone Number",
            gender: "Gender",
            male: "Male",
            female: "Female",
            other: "Other",
            email: "Email",
            password: "Password",
            confirmPassword: "Confirm Password",
            saveChanges: "Save Changes"
        },
        billing: {
            subscriptionPlan: "Subscription Plan",
            standard: "Standard",
            monthlyPlan: "Monthly Plan",
            cancelSubscription: "Cancel Subscription",
            nextPayment: "Your next payment is",
            willBeChargedOn: "which will be charged on",
            autoRenewal: "The payment will automatically renew every month"
        },
        paymentMethod: {
            title: "Payment Method",
            description: "Choose your preferred payment method for future payments.",
            endingIn: "ending in",
            expires: "Expires on",
            primaryCard: "Primary Card",
            setAsPrimary: "Set as Primary",
            edit: "Edit",
            addNew: "Add New Payment Method"
        },
        emojichats: {
            message:"Register for Free to use this and more options ",
        },
        errors: {
            sessionNotRecovered: 'Session could not be recovered.'
        }
    },
    es: {
        cookies:{
            cookieTitle:"Solo para Adultos: Personalizamos Tu Placer",
            cookieWarning:"Utilizamos cookies y tecnologías similares, incluidas cookies de terceros, para brindarte una experiencia más inmersiva y personalizada. Estos nos ayudan a optimizar el sitio, analizar el tráfico, adaptar el contenido a tus preferencias y ofrecerte interacciones más fluidas. Al continuar navegando, aceptas nuestro uso de cookies. Para obtener más información, consulta nuestra ",
            cookiePolicy: "Política de cookies",
            accept: "Aceptar Cookies",
            manage: "Gestionar o rechazar Cookies",
            rejectAll: "Rechazar todas",
            acceptAll: "Aceptar todas",
            close: "Cerrar",
            manageTitle: "Gestiona tus preferencias de Cookies",
            manageText: "Selecciona tus preferencias de cookies.",
            adultWarning: "Novia Virtual contiene contenido para adultos. Al utilizar el sitio web usted reconoce que es mayor de 18 años."
        },
        race: {
            title1: "Latina",
            title2: "Blanca",
            title3: "Negra",
            title4: "Asiática",
            title5: "Árabe",
            title6: "Hindu",
            description1: "Las latinas son pasión pura, con curvas irresistibles y un fuego interno que no podrás apagar.",
            description2: "Las chicas blancas te cautivarán con su elegancia, belleza angelical y un toque de picardía irresistible.",
            description3: "Las mujeres negras desbordan sensualidad, energía y un ritmo ardiente que te hará perder el control.",
            description4: "Las asiáticas combinan dulzura y misterio, con una sensualidad exótica que te dejará sin aliento.",
            description5: "Las mujeres árabes son una mezcla perfecta de encanto y misticismo, listas para desatar tus deseos más ocultos.",
            description6: "Las hindúes tienen una belleza hipnotizante y una seducción envolvente que te atrapará en su hechizo."
        },
        body: {
            title1: "Delgada",
            title2: "Atlética",
            title3: "Con Curvas",
            title4: "Gordita",
            description1: "Las delgadas son puro encanto y sensualidad, con cuerpos esbeltos que te invitan a descubrir cada centímetro.",
            description2: "Las atléticas tienen la combinación perfecta de fuerza y feminidad, listas para llevarte a un juego sin límites.",
            description3: "Las chicas con curvas saben cómo usar su cuerpo para seducirte, con movimientos que te dejarán sin aliento.",
            description4: "Las gorditas son dulzura y fuego en un solo paquete, con una sensualidad envolvente que te hará desear más."
        },
        height: {
            description1: "Pequeñas pero irresistibles, con una sensualidad que desafía su estatura y una actitud juguetona que te encantará.",
            description2: "La altura perfecta para disfrutar de cada momento, con un equilibrio de elegancia y pasión que te atrapará.",
            description3: "Altas y seductoras, con piernas de infarto y una presencia que te hará desear cada instante a su lado.",
            description4: "Imponentes y llenas de magnetismo, con una sensualidad que domina y un cuerpo diseñado para el placer."
        },
        hair: {
            title1: "Negro",
            title2: "Castaño",
            title3: "Rubia",
            title4: "Pelirroja",
            title5: "Otro color",
            description1: "Cabello negro como la noche, profundo y misterioso, listo para envolverte en su encanto irresistible.",
            description2: "Las castañas combinan dulzura y picardía, con una sensualidad natural que te atrapará al instante.",
            description3: "Las rubias son el centro de todas las miradas, con una chispa de atrevimiento que las hace irresistibles.",
            description4: "Las pelirrojas arden en pasión, con un fuego interno que hará que pierdas la cabeza en cada encuentro.",
            description5: "Atrevidas y únicas, con un look fuera de lo común que refleja una personalidad ardiente e inigualable."
        },
        age: {
            title1: "Madura",
            description1: 'La curiosidad y la pasión se fusionan en una aventura llena de descubrimientos.',
            description2: 'Colegialas, universitarias y esposas atrevidas listas para cumplir tus fantasías más ardientes',
            description3: 'Las MILFs de 30 a 40 años tienen la confianza y la experiencia para llevarte al límite del placer.',
            description4: 'Mayores de 40, seductoras y sin inhibiciones, listas para mostrarte todo lo que han aprendido.',
        },
        alert: {
            title: 'Novias Virtuales en Línea',
            description: 'Chatea con novias virtuales en vivo en NoviaVirtual.com. Conexiones íntimas y personalizadas con las chicas más populares. ¡Habla ahora!'
        },
        agent: {
            status: 'EN LÍNEA',
            addFavorite: 'Agregar a favoritas',
            removeFavorite: 'Eliminar de favoritas',
            privateChat: "HABLEMOS EN PRIVADO",
            viewGallery: "Ver galería",
            gallery: {
                noImages: "No se han encontrado imágenes.",
                image: "Imagen de galería"
            },
            sendGift: "Enviar regalo",
            buyCredits: "Comprar créditos"
        },
        home: {
            h1: 'Conéctate Con Tu <span class="highlight-text">Novia Virtual</span>',
            description: 'Disfruta de momentos únicos con tu compañera ideal. Charla, conecta y vive<br>experiencias inolvidables desde cualquier lugar, llevando la emoción y la<br>compañía siempre contigo.',
        },
        header: {
            login: 'INICIAR SESIÓN',
            register: 'REGISTRARSE',
            access: 'ACCESO',
            searchLanguage: 'Buscar idioma',
            explore: 'EXPLORAR',
            chats: 'MENSAJES',
            logout: 'Salir',
            messages: 'Mensajes',
            profile: 'Perfil',
            myFavorites: 'Mis Favoritas',
        },
        auth: {
            register: {
                title: 'Crear una cuenta',
                description: 'Ingresa tu correo y contraseña para crear\nuna nueva cuenta.',
                email: 'Correo electrónico',
                password: 'Contraseña',
                confirmPassword: 'Confirmar contraseña',
                button: 'CREAR CUENTA',
                haveAccount: '¿Ya tienes una cuenta?',
                loginHere: '¡Inicia sesión aquí!',
                validation: {
                    emailRequired: 'El correo es requerido',
                    emailValid: 'El correo debe ser válido',
                    passwordRequired: 'La contraseña es requerida',
                    passwordLength: 'La contraseña debe tener al menos 8 caracteres',
                    confirmRequired: 'Confirma tu contraseña',
                    passwordsMatch: 'Las contraseñas no coinciden',
                    formErrors: 'Por favor, corrige los errores en el formulario'
                },
                success: '¡Registro exitoso!',
                errors: {
                    'email-taken': 'Este correo ya está registrado',
                    'password-length': 'La contraseña debe tener al menos 8 caracteres',
                    'password-confirmed': 'Las contraseñas no coinciden'
                }
            },
            login: {
                title: 'Inicio de sesión',
                description: 'Ingresa tu correo y contraseña para acceder.',
                email: 'Correo electrónico',
                password: 'Contraseña',
                forgotPassword: '¿Recuperar contraseña?',
                button: 'ACCEDER',
                noAccount: '¿Aun no tienes una cuenta?',
                registerHere: '¡Crea tu cuenta aquí!',
                validation: {
                    emailRequired: 'El correo es requerido',
                    emailValid: 'El correo debe ser válido',
                    passwordRequired: 'La contraseña es requerida',
                    formErrors: 'Por favor, corrige los errores en el formulario'
                },
                success: '¡Inicio de sesión exitoso!',
                errors: {
                    'invalid-credentials': 'Credenciales inválidas',
                    'email-not-found': 'Usuario no encontrado',
                    'invalid-password': 'Contraseña incorrecta',
                    'account-disabled': 'Cuenta deshabilitada'
                }
            },
            resetPassword: {
                title: 'Recuperar Contraseña',
                description: 'Ingresa tu correo para recibir instrucciones.',
                email: 'Correo electrónico',
                button: 'ENVIAR INSTRUCCIONES',
                rememberPassword: '¿Recordaste tu contraseña?',
                backToLogin: '¡Volver al inicio de sesión!',
                validation: {
                    emailRequired: 'El correo es requerido',
                    emailValid: 'El correo debe ser válido',
                    formErrors: 'Por favor, revisa los campos del formulario'
                },
                success: 'Instrucciones enviadas a tu correo',
                errors: {
                    'email-not-found': 'Este correo electrónico no está registrado.',
                    'too-many-requests': 'Demasiadas solicitudes de restablecimiento de contraseña. Por favor, inténtelo de nuevo más tarde.',
                    'cannot-send-link': 'No se pudo enviar el enlace de restablecimiento de contraseña. Verifique su correo o intente más tarde.',
                    'server-error': 'Ocurrió un error inesperado. Por favor, inténtelo de nuevo más tarde.'
                }
            },
            changePassword: {
                title: 'Crear Nueva Contraseña',
                description: 'Ingresa una nueva contraseña para tu cuenta',
                password: 'Nueva Contraseña',
                confirmPassword: 'Confirmar Nueva Contraseña',
                button: 'CAMBIAR CONTRASEÑA',
                validation: {
                    passwordRequired: 'La contraseña es requerida',
                    passwordLength: 'La contraseña debe tener al menos 8 caracteres',
                    confirmRequired: 'Por favor confirma tu contraseña',
                    passwordsMatch: 'Las contraseñas no coinciden',
                    formErrors: 'Por favor revisa los campos del formulario'
                },
                errors: {
                    'invalid-token': 'El enlace de restablecimiento de contraseña es inválido. Por favor, solicita un nuevo enlace.',
                    'reset-failed': 'No se pudo restablecer la contraseña. Por favor, inténtalo de nuevo.',
                    'server-error': 'Ocurrió un error inesperado. Por favor, inténtelo de nuevo más tarde.'
                },
                success: 'Contraseña cambiada con éxito. Redirigiendo al login...'
            }
        },
        sidebar: {
            menu: {
                explore: 'EXPLORAR',
                categories: 'Categorías',
                chats: 'MENSAJES'
            },
            footer: {
                terms: 'Términos y condiciones',
                rights: '© 2024 Todos los derechos reservados'
            },
            showMore: 'Ver Más',
            showLess: 'Ver Menos',
            showMoreCount: 'Ver Más ({count})',
            popularTags: 'Etiquetas',
            categories: 'Categorías',
            languages: 'Idiomas',
            details_relationship: 'Detalles de la relación',
            close_profile: "Cerrar perfil",
            show_profile: "Mostrar perfil"
        },
        error: {
            404: {
                title: 'Página no encontrada',
                description: 'La página que buscas no existe o ha sido movida.',
                button: 'Volver al Inicio'
            }
        },
        subcategories: {
            noResults: 'No se encontraron resultados',
            tryAnother: 'Intenta con otra categoría o subcategoría'
        },
        tags: {
            noResults: 'No se encontraron agentes con esta etiqueta',
            tryAnother: 'Intenta con otra etiqueta'
        },
        discover: {
            moreAgents: "Descubre aquí más novias virtuales"
        },
        chat: {
            connectedAs: "Conectado como:",
            publicMessage: "Mensaje público...",
            waitMessage: "Por favor, espera {seconds} segundos para enviar otro mensaje.",
            messageRemoved: "El mensaje se eliminará en {seconds} segundos"
        },
        profileMenu: {
            profile: 'Perfil',
            billding: 'Facturación'
        },
        profile: {
            uploadPhoto: "Cargar foto",
            sizeLimit: "El tamaño máximo de carga es 1 MB.",
            editProfile: "Editar perfil",
            username: "Nombre de usuario",
            birthdate: "Fecha de nacimiento",
            phoneIndicator: "Indicador de teléfono",
            phoneNumber: "Número de teléfono",
            gender: "Género",
            male: "Hombre",
            female: "Mujer",
            other: "Otro",
            email: "Correo",
            password: "Contraseña",
            confirmPassword: "Confirmar contraseña",
            saveChanges: "Guardar cambios"
        },
        billing: {
            subscriptionPlan: "Plan de Suscripción",
            standard: "Estándar",
            monthlyPlan: "Plan Mensual",
            cancelSubscription: "Cancelar suscripción",
            nextPayment: "Tu próximo pago es",
            willBeChargedOn: "que se cobrará en",
            autoRenewal: "El pago se renovará automáticamente cada mes"
        },
        paymentMethod: {
            title: "Método de Pago",
            description: "Elija el método de pago que prefiera para efectuar futuros pagos.",
            endingIn: "finalizada en",
            expires: "Expira el",
            primaryCard: "Tarjeta Principal",
            setAsPrimary: "Establecer como Principal",
            edit: "Editar",
            addNew: "Agregar Nuevo Método de Pago"
        },
        emojichats: {
            message: "Regístrate Gratis, para usar esta y más opciones "
        },
        errors: {
            sessionNotRecovered: 'No se pudo recuperar la sesión.'
        }
    },
    fr: {
        cookies: {
            cookieTitle: "Réservé aux Adultes: Nous Personnalisons Votre Plaisir",
            cookieWarning: "Nous utilisons des cookies et des technologies similaires, y compris des cookies tiers, pour vous offrir une expérience plus immersive et personnalisée. Ceux-ci nous aident à optimiser le site, analyser le trafic, adapter le contenu à vos préférences et vous offrir des interactions plus fluides. En continuant à naviguer, vous acceptez notre utilisation des cookies. Pour plus d'informations, consultez notre ",
            cookiePolicy: "Politique de cookies",
            accept: "Accepter les Cookies",
            manage: "Gérer ou refuser les Cookies",
            rejectAll: "Tout refuser",
            acceptAll: "Tout accepter",
            close: "Fermer",
            manageTitle: "Gérez vos préférences de Cookies",
            manageText: "Sélectionnez vos préférences de cookies.",
            adultWarning: "NovaVirtual contient du contenu pour adultes. En utilisant le site web, vous reconnaissez avoir plus de 18 ans."
        },
        race: {
            title1: "Latina",
            title2: "Blanche",
            title3: "Noire",
            title4: "Asiatique",
            title5: "Arabe",
            title6: "Indienne",
            description1: "Les Latinas sont une pure passion, avec des courbes irrésistibles et un feu intérieur que tu ne pourras pas éteindre.",
            description2: "Les filles blanches te captiveront par leur élégance, leur beauté angélique et une touche d’espièglerie irrésistible.",
            description3: "Les femmes noires débordent de sensualité, d’énergie et d’un rythme enflammé qui te fera perdre le contrôle.",
            description4: "Les Asiatiques allient douceur et mystère, avec une sensualité exotique qui te laissera sans souffle.",
            description5: "Les femmes arabes sont un mélange parfait de charme et de mysticisme, prêtes à libérer tes désirs les plus profonds.",
            description6: "Les femmes indiennes ont une beauté envoûtante et une séduction captivante qui te prendra dans leur sortilège."
        },
        body: {
            title1: "Mince",
            title2: "Athlétique",
            title3: "Courbée",
            title4: "Ronde",
            description1: "Les femmes minces sont un mélange de charme et de sensualité, avec des corps élancés qui t’invitent à explorer chaque centimètre.",
            description2: "Les femmes athlétiques ont le parfait mélange de force et de féminité, prêtes à t’emmener dans un jeu sans limites.",
            description3: "Les femmes aux courbes généreuses savent comment utiliser leur corps pour te séduire, avec des mouvements qui te laisseront sans souffle.",
            description4: "Les femmes rondes sont à la fois douceur et feu, avec une sensualité enivrante qui te donnera envie de plus."
        },
        height: {
            description1: "Petites mais irrésistibles, avec une sensualité qui défie leur taille et une attitude espiègle qui te fera craquer.",
            description2: "La taille parfaite pour savourer chaque instant, avec un équilibre d’élégance et de passion qui te captivera.",
            description3: "Grandes et séduisantes, avec des jambes à couper le souffle et une présence qui te fera désirer chaque moment à leurs côtés.",
            description4: "Imposantes et pleines de magnétisme, avec une sensualité dominante et un corps conçu pour le plaisir."
        },
        hair: {
            title1: "Noir",
            title2: "Brun",
            title3: "Blond",
            title4: "Roux",
            title5: "Autre couleur",
            description1: "Des cheveux noirs aussi profonds que la nuit, mystérieux et envoûtants, prêts à t’ensorceler par leur charme irrésistible.",
            description2: "Les brunes allient douceur et espièglerie, avec une sensualité naturelle qui te captivera instantanément.",
            description3: "Les blondes attirent tous les regards, avec une touche d’audace qui les rend irrésistibles.",
            description4: "Les rousses brûlent de passion, avec un feu intérieur qui te fera perdre la tête à chaque rencontre.",
            description5: "Audacieuses et uniques, avec un look hors du commun qui reflète une personnalité intense et inégalée."
        },        
        age: {
            title1: "Mûre",
            description1: "La curiosité et la passion se fusionnent dans une aventure pleine de découvertes.",
            description2: "Écolières, étudiantes et épouses audacieuses prêtes à réaliser vos fantasmes les plus fous.",
            description3: "Les MILFs de 30 à 40 ans ont la confiance et l'expérience pour vous mener au sommet du plaisir.",
            description4: "Plus de 40 ans, séduisantes et sans inhibitions, prêtes à vous montrer tout ce qu'elles ont appris."
        },
        alert: {
            title: 'Petites Amies Virtuelles en Ligne',
            description: 'Chattez avec des petites amies virtuelles en direct sur NovaVirtual.com. Connexions intimes et personnalisées avec les filles les plus populaires. Chattez maintenant!'
        },
        agent: {
            status: 'EN LIGNE',
            addFavorite: 'Ajouter aux favorites',
            removeFavorite: 'Retirer des favorites',
            privateChat: "PARLONS EN PRIVÉ",
            viewGallery: "Voir la galerie",
            gallery: {
                noImages: "Aucune image trouvée.",
                image: "Image de la galerie"
            },
            sendGift: "Envoyer un cadeau",
            buyCredits: "Acheter des crédits"
        },
        home: {
            h1: 'Connectez-vous Avec Votre <span class="highlight-text">Petite Amie Virtuelle</span>',
            description: 'Profitez de moments uniques avec votre compagne idéale. <br>Discutez, connectez-vous et vivez des expériences inoubliables de<br> n\'importe où, en gardant l\'excitation et la compagnie toujours avec vous.',
        },
        header: {
            login: 'CONNEXION',
            register: 'S\'INSCRIRE',
            access: 'ACCÈS',
            searchLanguage: 'Rechercher une langue',
            explore: 'EXPLORER',
            chats: 'MESSAGES',
            logout: 'Quitter',
            messages: 'Messages',
            profile: 'Profil',
            myFavorites: 'Mes Favoris',
        },
        auth: {
            register: {
                title: 'Créer un compte',
                description: 'Entrez votre email et mot de passe pour créer\nun nouveau compte.',
                email: 'Email',
                password: 'Mot de passe',
                confirmPassword: 'Confirmer le mot de passe',
                button: 'CRÉER UN COMPTE',
                haveAccount: 'Vous avez déjà un compte ?',
                loginHere: 'Connectez-vous ici !',
                validation: {
                    emailRequired: "L'email est requis",
                    emailValid: "L'email doit être valide",
                    passwordRequired: 'Le mot de passe est requis',
                    passwordLength: 'Le mot de passe doit contenir au moins 8 caractères',
                    confirmRequired: 'Confirmez votre mot de passe',
                    passwordsMatch: 'Les mots de passe ne correspondent pas',
                    formErrors: 'Veuillez corriger les erreurs du formulaire'
                },
                success: 'Inscription réussie !',
                errors: {
                    'email-taken': 'Cet email est déjà enregistré',
                    'password-length': 'Le mot de passe doit contenir au moins 8 caractères',
                    'password-confirmed': 'Les mots de passe ne correspondent pas'
                }
            },
            login: {
                title: 'Connexion',
                description: 'Entrez votre email et mot de passe pour accéder.',
                email: 'Email',
                password: 'Mot de passe',
                forgotPassword: 'Mot de passe oublié ?',
                button: 'SE CONNECTER',
                noAccount: "Vous n'avez pas de compte ?",
                registerHere: 'Créez votre compte ici !',
                validation: {
                    emailRequired: "L'email est requis",
                    emailValid: "L'email doit être valide",
                    passwordRequired: 'Le mot de passe est requis',
                    formErrors: 'Veuillez corriger les erreurs du formulaire'
                },
                success: 'Connexion réussie !',
                errors: {
                    'invalid-credentials': 'Identifiants invalides',
                    'email-not-found': 'Utilisateur non trouvé',
                    'invalid-password': 'Mot de passe incorrect',
                    'account-disabled': 'Compte désactivé'
                }
            },
            resetPassword: {
                title: 'Réinitialiser le Mot de Passe',
                description: "Entrez votre adresse e-mail pour recevoir les instructions.",
                email: 'Email',
                button: 'ENVOYER LES INSTRUCTIONS',
                rememberPassword: 'Vous vous souvenez de votre mot de passe ?',
                backToLogin: 'Retour à la connexion !',
                validation: {
                    emailRequired: "L'email est requise",
                    emailValid: "L'email doit être valide",
                    formErrors: 'Veuillez vérifier les champs du formulaire'
                },
                success: 'Instructions envoyées à votre email',
                errors: {
                    'email-not-found': "Cette adresse e-mail n'est pas enregistrée.",
                    'too-many-requests': 'Trop de demandes de réinitialisation de mot de passe. Veuillez réessayer plus tard.',
                    'cannot-send-link': "Impossible d'envoyer le lien de réinitialisation du mot de passe. Vérifiez votre e-mail ou réessayez plus tard.",
                    'server-error': 'Une erreur imprévue est survenue. Veuillez réessayer plus tard.'
                }
            },
            changePassword: {
                title: 'Créer un Nouveau Mot de Passe',
                description: 'Entrez un nouveau mot de passe pour votre compte',
                password: 'Nouveau Mot de Passe',
                confirmPassword: 'Confirmer le Nouveau Mot de Passe',
                button: 'CHANGER LE MOT DE PASSE',
                validation: {
                    passwordRequired: 'Le mot de passe est requis',
                    passwordLength: 'Le mot de passe doit contenir au moins 8 caractères',
                    confirmRequired: 'Veuillez confirmer votre mot de passe',
                    passwordsMatch: 'Les mots de passe ne correspondent pas',
                    formErrors: 'Veuillez vérifier les champs du formulaire'
                },
                errors: {
                    'invalid-token': 'Le lien de réinitialisation du mot de passe est invalide. Veuillez demander un nouveau lien.',
                    'reset-failed': 'Impossible de réinitialiser le mot de passe. Veuillez réessayer.',
                    'server-error': 'Une erreur inattendue est survenue. Veuillez réessayer plus tard.'
                },
                success: 'Mot de passe changé avec succès. Redirection vers la connexion...'
            }
        },
        sidebar: {
            menu: {
                explore: 'EXPLORER',
                categories: 'Catégories',
                chats: 'MESSAGES'
            },
            footer: {
                terms: 'Termes et conditions',
                rights: '© 2024 Tous droits réservés'
            },
            showMore: 'Voir Plus',
            showLess: 'Voir Moins',
            showMoreCount: 'Voir Plus ({count})',
            popularTags: 'Tags',
            categories: 'Catégories',
            languages: 'Langues',
            details_relationship: 'Détails de la relation',
            close_profile: "Fermer le profil",
            show_profile: "Afficher le profil"
        },
        error: {
            404: {
                title: 'Page non trouvée',
                description: 'La page que vous recherchez n\'existe pas ou a été déplacée.',
                button: 'Retour à l\'accueil'
            }
        },
        subcategories: {
            noResults: 'Aucun résultat trouvé',
            tryAnother: 'Essayez une autre catégorie ou sous-catégorie'
        },
        tags: {
            noResults: 'Aucun agent trouvé avec cette étiquette',
            tryAnother: 'Essayez une autre étiquette'
        },
        discover: {
            moreAgents: "Découvrez plus de petites amies virtuelles ici"
        },
        chat: {
            connectedAs: "Connecté en tant que:",
            publicMessage: "Message public...",
            waitMessage: "Veuillez attendre {seconds} secondes pour envoyer un autre message.",
            messageRemoved: "Le message sera supprimé dans {seconds} secondes"
        },
        profileMenu: {
            profile: 'Perfil',
            billding: 'Facturación'
        },
        profile: {
            uploadPhoto: "Télécharger la photo",
            sizeLimit: "La taille maximale de téléchargement est de 1 MB.",
            editProfile: "Modifier le profil",
            username: "Nom d'utilisateur",
            birthdate: "Date de naissance",
            phoneIndicator: "Indicatif téléphonique",
            phoneNumber: "Numéro de téléphone",
            gender: "Genre",
            male: "Homme",
            female: "Femme",
            other: "Autre",
            email: "Email",
            password: "Mot de passe",
            confirmPassword: "Confirmer le mot de passe",
            saveChanges: "Enregistrer les modifications"
        },
        billing: {
            subscriptionPlan: "Plan d'abonnement",
            standard: "Standard",
            monthlyPlan: "Plan Mensuel",
            cancelSubscription: "Annuler l'abonnement",
            nextPayment: "Votre prochain paiement est",
            willBeChargedOn: "qui sera facturé le",
            autoRenewal: "Le paiement sera automatiquement renouvelé chaque mois"
        },
        paymentMethod: {
            title: "Méthode de Paiement",
            description: "Choisissez votre méthode de paiement préférée pour les paiements futurs.",
            endingIn: "se terminant par",
            expires: "Expire le",
            primaryCard: "Carte Principale",
            setAsPrimary: "Définir comme Principale",
            edit: "Modifier",
            addNew: "Ajouter une Nouvelle Méthode de Paiement"
        },
        emojichats: {
            message: "Inscrivez-vous Gratuitement, pour utiliser cette option et plus encore "
        },
        errors: {
            sessionNotRecovered: 'La session n\'a pas pu être récupérée.'
        }
    },
    de: {
        cookies: {
            cookieTitle: "Nur für Erwachsene: Wir Personalisieren Ihr Vergnügen",
            cookieWarning: "Wir verwenden Cookies und ähnliche Technologien, einschließlich Cookies von Drittanbietern, um Ihnen ein intensiveres und personalisiertes Erlebnis zu bieten. Diese helfen uns, die Website zu optimieren, den Verkehr zu analysieren, Inhalte an Ihre Vorlieben anzupassen und Ihnen flüssigere Interaktionen zu bieten. Indem Sie weiter surfen, stimmen Sie unserer Verwendung von Cookies zu. Weitere Informationen finden Sie in unserer ",
            cookiePolicy: "Cookie-Richtlinie",
            accept: "Cookies Akzeptieren",
            manage: "Cookies Verwalten oder Ablehnen",
            rejectAll: "Alle Ablehnen",
            acceptAll: "Alle Akzeptieren",
            close: "Schließen",
            manageTitle: "Verwalten Sie Ihre Cookie-Einstellungen",
            manageText: "Wählen Sie Ihre Cookie-Einstellungen.",
            adultWarning: "NovaVirtual enthält Inhalte für Erwachsene. Durch die Nutzung der Website erkennen Sie an, dass Sie über 18 Jahre alt sind."
        },
        race: {
            title1: "Latina",
            title2: "Weiß",
            title3: "Schwarz",
            title4: "Asiatisch",
            title5: "Arabisch",
            title6: "Indisch",
            description1: "Latinas sind pure Leidenschaft, mit unwiderstehlichen Kurven und einem inneren Feuer, das du nicht löschen kannst.",
            description2: "Weiße Frauen verzaubern dich mit ihrer Eleganz, engelsgleichen Schönheit und einem Hauch von unwiderstehlichem Charme.",
            description3: "Schwarze Frauen strahlen Sinnlichkeit, Energie und ein feuriges Temperament aus, das dich den Verstand verlieren lässt.",
            description4: "Asiatinnen vereinen Sanftheit und Geheimnis, mit einer exotischen Sinnlichkeit, die dir den Atem rauben wird.",
            description5: "Arabische Frauen sind eine perfekte Mischung aus Charme und Mystik, bereit, deine tiefsten Wünsche zu entfesseln.",
            description6: "Indische Frauen haben eine hypnotisierende Schönheit und eine fesselnde Verführung, die dich in ihren Bann zieht."
        },
        body: {
            title1: "Schlank",
            title2: "Athletisch",
            title3: "Kurvig",
            title4: "Kräftig",
            description1: "Schlanke Frauen sind pure Anmut und Sinnlichkeit, mit schlanken Körpern, die dazu einladen, jeden Zentimeter zu entdecken.",
            description2: "Athletische Frauen haben die perfekte Kombination aus Stärke und Weiblichkeit, bereit, dich in ein grenzenloses Spiel zu entführen.",
            description3: "Kurvige Frauen wissen, wie sie ihren Körper einsetzen können, um dich zu verführen – mit Bewegungen, die dich sprachlos machen.",
            description4: "Mollige Frauen vereinen Süße und Feuer in einem, mit einer fesselnden Sinnlichkeit, die dich nach mehr verlangen lässt."
        },
        height: {
            description1: "Klein, aber unwiderstehlich – mit einer Sinnlichkeit, die ihre Größe übertrifft, und einer verspielten Art, die dich verzaubern wird.",
            description2: "Die perfekte Größe, um jeden Moment zu genießen, mit einer Balance aus Eleganz und Leidenschaft, die dich fesseln wird.",
            description3: "Groß und verführerisch, mit atemberaubenden Beinen und einer Präsenz, die dich nach jeder Sekunde mit ihr sehnen lässt.",
            description4: "Imposant und voller Anziehungskraft, mit einer dominanten Sinnlichkeit und einem Körper, der für Vergnügen geschaffen wurde."
        },
        hair: {
            title1: "Schwarz",
            title2: "Braun",
            title3: "Blond",
            title4: "Rothaarig",
            title5: "Andere Farbe",
            description1: "Schwarzes Haar so tief wie die Nacht, geheimnisvoll und verführerisch, bereit, dich mit seinem unwiderstehlichen Charme zu umhüllen.",
            description2: "Brünette verbinden Sanftheit und Verspieltheit mit einer natürlichen Sinnlichkeit, die dich sofort fesseln wird.",
            description3: "Blondinen stehen immer im Mittelpunkt, mit einem Hauch von Kühnheit, der sie unwiderstehlich macht.",
            description4: "Rothaarige brennen vor Leidenschaft, mit einem inneren Feuer, das dich bei jedem Treffen den Verstand verlieren lässt.",
            description5: "Mutig und einzigartig, mit einem außergewöhnlichen Look, der eine feurige und unvergleichliche Persönlichkeit widerspiegelt."
        },        
        age: {
            title1: "Reif",
            description1: "Neugier und Leidenschaft verschmelzen zu einem Abenteuer voller Entdeckungen.",
            description2: "Schülerinnen, Studentinnen und kühne Ehefrauen, bereit, deine wildesten Fantasien zu erfüllen.",
            description3: "MILFs im Alter von 30 bis 40 Jahren haben das Selbstbewusstsein und die Erfahrung, um dich an die Grenzen des Vergnügens zu bringen.",
            description4: "Über 40, verführerisch und hemmungslos, bereit, dir alles zu zeigen, was sie gelernt haben."
        },
        alert: {
            title: 'Virtuelle Freundinnen Online',
            description: 'Chatte mit virtuellen Freundinnen live auf NovaVirtual.com. Intime und personalisierte Verbindungen mit den beliebtesten Mädchen. Chatte jetzt!'
        },
        agent: {
            status: 'ONLINE',
            addFavorite: 'Zu Favoritinnen hinzufügen',
            removeFavorite: 'Aus Favoritinnen entfernen',
            privateChat: "LASS UNS PRIVAT REDEN",
            viewGallery: "Galerie ansehen",
            gallery: {
                noImages: "Keine Bilder gefunden.",
                image: "Galeriebild"
            },
            sendGift: "Geschenk senden",
            buyCredits: "Guthaben kaufen"
        },
        home: {
            h1: 'Verbinden Sie Sich Mit Ihrer <span class="highlight-text">Virtuellen Freundin</span>',
            description: 'Genießen Sie einzigartige Momente mit Ihrer idealen Begleiterin. Chatten, verbinden und erleben unvergessliche Erlebnisse von überall, wobei<br> Sie die Aufregung und Gesellschaft immer bei sich haben.',
        },
        header: {
            login: 'ANMELDEN',
            register: 'REGISTRIEREN',
            access: 'ZUGANG',
            searchLanguage: 'Sprache suchen',
            explore: 'ERKUNDEN',
            chats: 'NACHRICHTEN',
            logout: 'Verlassen',
            messages: 'Nachrichten',
            profile: 'Profil',
            myFavorites: 'Meine Favoriten',
        },
        auth: {
            register: {
                title: 'Konto erstellen',
                description: 'Geben Sie Ihre E-Mail und Ihr Passwort ein, um\nein neues Konto zu erstellen.',
                email: 'E-Mail',
                password: 'Passwort',
                confirmPassword: 'Passwort bestätigen',
                button: 'KONTO ERSTELLEN',
                haveAccount: 'Haben Sie bereits ein Konto?',
                loginHere: 'Hier anmelden!',
                validation: {
                    emailRequired: 'E-Mail ist erforderlich',
                    emailValid: 'E-Mail muss gültig sein',
                    passwordRequired: 'Passwort ist erforderlich',
                    passwordLength: 'Passwort muss mindestens 8 Zeichen lang sein',
                    confirmRequired: 'Bestätigen Sie Ihr Passwort',
                    passwordsMatch: 'Passwörter stimmen nicht überein',
                    formErrors: 'Bitte korrigieren Sie die Formularfehler'
                },
                success: 'Registrierung erfolgreich!',
                errors: {
                    'email-taken': 'Diese E-Mail ist bereits registriert',
                    'password-length': 'Das Passwort muss mindestens 8 Zeichen lang sein',
                    'password-confirmed': 'Passwörter stimmen nicht überein'
                }
            },
            login: {
                title: 'Anmelden',
                description: 'Geben Sie Ihre E-Mail und Ihr Passwort ein.',
                email: 'E-Mail',
                password: 'Passwort',
                forgotPassword: 'Passwort vergessen?',
                button: 'ANMELDEN',
                noAccount: 'Noch kein Konto?',
                registerHere: 'Hier registrieren!',
                validation: {
                    emailRequired: 'E-Mail ist erforderlich',
                    emailValid: 'E-Mail muss gültig sein',
                    passwordRequired: 'Passwort ist erforderlich',
                    formErrors: 'Bitte korrigieren Sie die Formularfehler'
                },
                success: 'Anmeldung erfolgreich!',
                errors: {
                    'invalid-credentials': 'Ungültige Anmeldeinformationen',
                    'email-not-found': 'Benutzer nicht gefunden',
                    'invalid-password': 'Falsches Passwort',
                    'account-disabled': 'Konto deaktiviert'
                }
            },
            resetPassword: {
                title: 'Passwort Zurücksetzen',
                description: 'Geben Sie Ihre E-Mail-Adresse ein, um Anweisungen zu erhalten.',
                email: 'E-Mail',
                button: 'ANWEISUNGEN SENDEN',
                rememberPassword: 'Passwort wieder eingefallen?',
                backToLogin: 'Zurück zum Login!',
                validation: {
                    emailRequired: 'E-Mail ist erforderlich',
                    emailValid: 'E-Mail muss gültig sein',
                    formErrors: 'Bitte überprüfen Sie die Formularfelder'
                },
                success: 'Anweisungen an Ihre E-Mail gesendet',
                errors: {
                    'email-not-found': 'Diese E-Mail-Adresse ist nicht registriert.',
                    'too-many-requests': 'Zu viele Anfragen zum Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut.',
                    'cannot-send-link': 'Der Link zum Zurücksetzen des Passworts konnte nicht gesendet werden. Überprüfen Sie Ihre E-Mail oder versuchen Sie es später erneut.',
                    'server-error': 'Es ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.'
                }
            },
            changePassword: {
                title: 'Neues Passwort Erstellen',
                description: 'Geben Sie ein neues Passwort für Ihr Konto ein',
                password: 'Neues Passwort',
                confirmPassword: 'Neues Passwort Bestätigen',
                button: 'PASSWORT ÄNDERN',
                validation: {
                    passwordRequired: 'Passwort ist erforderlich',
                    passwordLength: 'Das Passwort muss mindestens 8 Zeichen lang sein',
                    confirmRequired: 'Bitte bestätigen Sie Ihr Passwort',
                    passwordsMatch: 'Passwörter stimmen nicht überein',
                    formErrors: 'Bitte überprüfen Sie die Formularfelder'
                },
                errors: {
                    'invalid-token': 'Der Link zum Zurücksetzen des Passworts ist ungültig. Bitte fordern Sie einen neuen Link an.',
                    'reset-failed': 'Passwort konnte nicht zurückgesetzt werden. Bitte versuchen Sie es erneut.',
                    'server-error': 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
                },
                success: 'Passwort erfolgreich geändert. Weiterleitung zur Anmeldung...'
            }
        },
        sidebar: {
            menu: {
                explore: 'ERKUNDEN',
                categories: 'Kategorien',
                chats: 'NACHRICHTEN'
            },
            footer: {
                terms: 'Geschäftsbedingungen',
                rights: '© 2024 Alle Rechte vorbehalten'
            },
            showMore: 'Mehr Anzeigen',
            showLess: 'Weniger Anzeigen',
            showMoreCount: 'Mehr Anzeigen ({count})',
            popularTags: 'Tags',
            categories: 'Kategorien',
            languages: 'Sprachen',
            details_relationship: 'Beziehungsdetails'
        },
        error: {
            404: {
                title: 'Seite nicht gefunden',
                description: 'Die gesuchte Seite existiert nicht oder wurde verschoben.',
                button: 'Zurück zur Startseite'
            }
        },
        subcategories: {
            noResults: 'Keine Ergebnisse gefunden',
            tryAnother: 'Versuchen Sie eine andere Kategorie oder Unterkategorie'
        },
        tags: {
            noResults: 'Keine Agenten mit diesem Tag gefunden',
            tryAnother: 'Versuchen Sie es mit einem anderen Tag'
        },
        discover: {
            moreAgents: "Entdecken Sie hier mehr virtuelle Freundinnen"
        },
        chat: {
            connectedAs: "Angemeldet als:",
            publicMessage: "Öffentliche Nachricht...",
            waitMessage: "Bitte warten Sie {seconds} Sekunden, um eine weitere Nachricht zu senden.",
            messageRemoved: "Die Nachricht wird in {seconds} Sekunden entfernt"
        },
        profileMenu: {
            profile: 'Profil',
            billding: 'Facturation'
        },
        profile: {
            uploadPhoto: "Foto hochladen",
            sizeLimit: "Die maximale Upload-Größe beträgt 1 MB.",
            editProfile: "Profil bearbeiten",
            username: "Benutzername",
            birthdate: "Geburtsdatum",
            phoneIndicator: "Telefonvorwahl",
            phoneNumber: "Telefonnummer",
            gender: "Geschlecht",
            male: "Männlich",
            female: "Weiblich",
            other: "Andere",
            email: "Email",
            password: "Passwort",
            confirmPassword: "Passwort bestätigen",
            saveChanges: "Änderungen speichern"
        },
        billing: {
            subscriptionPlan: "Abonnementplan",
            standard: "Standard",
            monthlyPlan: "Monatlicher Plan",
            cancelSubscription: "Abonnement kündigen",
            nextPayment: "Ihre nächste Zahlung ist",
            willBeChargedOn: "die am",
            autoRenewal: "Die Zahlung wird jeden Monat automatisch erneuert"
        },
        paymentMethod: {
            title: "Zahlungsmethode",
            description: "Wählen Sie Ihre bevorzugte Zahlungsmethode für zukünftige Zahlungen.",
            endingIn: "endet auf",
            expires: "Läuft ab am",
            primaryCard: "Hauptkarte",
            setAsPrimary: "Als Hauptkarte festlegen",
            edit: "Bearbeiten",
            addNew: "Neue Zahlungsmethode hinzufügen"
        },
        emojichats: {
            message: "Registriere dich Kostenlos, um diese und weitere Optionen zu nutzen "
        },
        errors: {
            sessionNotRecovered: 'Die Sitzung konnte nicht wiederhergestellt werden.'
        }
    },
    it: {
        cookies: {
            cookieTitle: "Solo per Adulti: Personalizziamo il Tuo Piacere",
            cookieWarning: "Utilizziamo cookie e tecnologie simili, inclusi cookie di terze parti, per offrirti un'esperienza più immersiva e personalizzata. Questi ci aiutano a ottimizzare il sito, analizzare il traffico, adattare i contenuti alle tue preferenze e offrirti interazioni più fluide. Continuando a navigare, accetti il nostro utilizzo dei cookie. Per maggiori informazioni, consulta la nostra ",
            cookiePolicy: "Politica sui cookie",
            accept: "Accetta i Cookie",
            manage: "Gestisci o rifiuta i Cookie",
            rejectAll: "Rifiuta tutti",
            acceptAll: "Accetta tutti",
            close: "Chiudi",
            manageTitle: "Gestisci le tue preferenze sui Cookie",
            manageText: "Seleziona le tue preferenze sui cookie.",
            adultWarning: "NovaVirtual contiene contenuti per adulti. Utilizzando il sito web, riconosci di avere più di 18 anni."
        },
        race: {
            title1: "Latina",
            title2: "Bianca",
            title3: "Nera",
            title4: "Asiatica",
            title5: "Araba",
            title6: "Indiana",
            description1: "Le latine sono pura passione, con curve irresistibili e un fuoco interiore che non potrai spegnere.",
            description2: "Le ragazze bianche ti incanteranno con la loro eleganza, bellezza angelica e un tocco di malizia irresistibile.",
            description3: "Le donne nere trasudano sensualità, energia e un ritmo infuocato che ti farà perdere il controllo.",
            description4: "Le asiatiche combinano dolcezza e mistero, con una sensualità esotica che ti lascerà senza fiato.",
            description5: "Le donne arabe sono un mix perfetto di fascino e misticismo, pronte a scatenare i tuoi desideri più nascosti.",
            description6: "Le donne indiane hanno una bellezza ipnotizzante e una seduzione avvolgente che ti catturerà nel loro incantesimo."
        },
        body: {
            title1: "Snella",
            title2: "Atletica",
            title3: "Formosa",
            title4: "Gordita",
            description1: "Le magre sono puro fascino e sensualità, con corpi slanciati che invitano a scoprire ogni centimetro.",
            description2: "Le atletiche hanno la combinazione perfetta di forza e femminilità, pronte a portarti in un gioco senza limiti.",
            description3: "Le donne con curve sanno come usare il loro corpo per sedurti, con movimenti che ti lasceranno senza fiato.",
            description4: "Le donne formose sono dolcezza e fuoco in un unico pacchetto, con una sensualità avvolgente che ti farà desiderare di più."
        },
        height: {
            description1: "Piccole ma irresistibili, con una sensualità che sfida la loro altezza e un atteggiamento giocoso che ti conquisterà.",
            description2: "L’altezza perfetta per godere di ogni momento, con un equilibrio di eleganza e passione che ti catturerà.",
            description3: "Alte e affascinanti, con gambe mozzafiato e una presenza che ti farà desiderare ogni attimo con loro.",
            description4: "Imponenti e piene di magnetismo, con una sensualità dominante e un corpo fatto per il piacere."
        },
        hair: {
            title1: "Nero",
            title2: "Castano",
            title3: "Biondo",
            title4: "Rosso",
            title5: "Altro colore",
            description1: "Capelli neri profondi come la notte, misteriosi e avvolgenti, pronti a incantarti con il loro fascino irresistibile.",
            description2: "Le brune combinano dolcezza e vivacità, con una sensualità naturale che ti conquisterà all’istante.",
            description3: "Le bionde catturano tutti gli sguardi, con un tocco di audacia che le rende irresistibili.",
            description4: "Le rosse bruciano di passione, con un fuoco interiore che ti farà perdere la testa ad ogni incontro.",
            description5: "Audaci e uniche, con un look fuori dal comune che riflette una personalità intensa e ineguagliabile."
        },        
        age: {
            title1: "Matura",
            description1: "Curiosità e passione si fondono in un'avventura piena di scoperte.",
            description2: "Scolarette, universitarie e mogli audaci pronte a realizzare le tue fantasie più sfrenate.",
            description3: "Le MILF dai 30 ai 40 anni hanno la sicurezza e l'esperienza per portarti al limite del piacere.",
            description4: "Oltre i 40 anni, seducenti e senza inibizioni, pronte a mostrarti tutto ciò che hanno imparato."
        },
        alert: {
            title: 'Fidanzate Virtuali Online',
            description: 'Chatta con fidanzate virtuali dal vivo su NovaVirtual.com. Connessioni intime e personalizzate con le ragazze più popolari. Chatta ora!'
        },
        agent: {
            status: 'IN LINEA',
            addFavorite: 'Aggiungi alle favorite',
            removeFavorite: 'Rimuovi dalle favorite',
            privateChat: "PARLIAMO IN PRIVATO",
            viewGallery: "Visualizza galleria",
            gallery: {
                noImages: "Nessuna immagine trovata.",
                image: "Immagine della galleria"
            },
            sendGift: "Invia regalo",
            buyCredits: "Acquista crediti"
        },
        home: {
            h1: 'Connettiti Con La Tua <span class="highlight-text">Fidanzata Virtuale</span>',
            description: 'Goditi momenti unici con la tua compagna ideale. Chatta, connettiti e vivi<br>esperienze indimenticabili da qualsiasi luogo, portando sempre con te<br>emozione e compagnia.',
        },
        header: {
            login: 'ACCEDI',
            register: 'REGISTRATI',
            access: 'ACCESSO',
            searchLanguage: 'Cerca lingua',
            explore: 'ESPLORA',
            chats: 'MESSAGGI',
            logout: 'Uscire',
            messages: 'Messaggi',
            profile: 'Profilo',
            myFavorites: 'Le Mie Preferite',
        },
        auth: {
            register: {
                title: 'Crea un account',
                description: 'Inserisci la tua email e password per creare\nun nuovo account.',
                email: 'Email',
                password: 'Password',
                confirmPassword: 'Conferma password',
                button: 'CREA ACCOUNT',
                haveAccount: 'Hai già un account?',
                loginHere: 'Accedi qui!',
                validation: {
                    emailRequired: "L'email è obbligatoria",
                    emailValid: "L'email deve essere valida",
                    passwordRequired: 'La password è obbligatoria',
                    passwordLength: 'La password deve contenere almeno 8 caratteri',
                    confirmRequired: 'Conferma la tua password',
                    passwordsMatch: 'Le password non coincidono',
                    formErrors: 'Correggi gli errori nel modulo'
                },
                success: 'Registrazione completata!',
                errors: {
                    'email-taken': 'Questa email è già registrata',
                    'password-length': 'La password deve contenere almeno 8 caratteri',
                    'password-confirmed': 'Le password non coincidono'
                }
            },
            login: {
                title: 'Accedi',
                description: 'Inserisci la tua email e password per accedere.',
                email: 'Email',
                password: 'Password',
                forgotPassword: 'Password dimenticata?',
                button: 'ACCEDI',
                noAccount: 'Non hai un account?',
                registerHere: 'Crea il tuo account qui!',
                validation: {
                    emailRequired: "L'email è obbligatoria",
                    emailValid: "L'email deve essere valida",
                    passwordRequired: 'La password è obbligatoria',
                    formErrors: 'Correggi gli errori nel modulo'
                },
                success: 'Accesso effettuato con successo!',
                errors: {
                    'invalid-credentials': 'Credenziali non valide',
                    'email-not-found': 'Utente non trovato',
                    'invalid-password': 'Password errata',
                    'account-disabled': 'Account disabilitato'
                }
            },
            resetPassword: {
                title: 'Reimposta Password',
                description: "Inserisci la tua email per ricevere l'istruzioni.",
                email: 'Email',
                button: 'INVIA ISTRUZIONI',
                rememberPassword: 'Ricordi la tua password?',
                backToLogin: 'Torna al login!',
                validation: {
                    emailRequired: "L'email è obbligatoria",
                    emailValid: "L'email deve essere valida",
                    formErrors: 'Controlla i campi del modulo'
                },
                success: 'Istruzioni inviate alla tua email',
                errors: {
                    'email-not-found': "Questo indirizzo email non è registrato.",
                    'too-many-requests': 'Troppe richieste di reimpostazione della password. Riprova più tardi.',
                    'cannot-send-link': 'Impossibile inviare il link per reimpostare la password. Controlla la tua email o riprova più tardi.',
                    'server-error': 'Si è verificato un errore imprevisto. Riprova più tardi.'
                }
            },
            changePassword: {
                title: 'Crea Nuova Password',
                description: 'Inserisci una nuova password per il tuo account',
                password: 'Nuova Password',
                confirmPassword: 'Conferma Nuova Password',
                button: 'CAMBIA PASSWORD',
                validation: {
                    passwordRequired: 'La password è obbligatoria',
                    passwordLength: 'La password deve contenere almeno 8 caratteri',
                    confirmRequired: 'Conferma la tua password',
                    passwordsMatch: 'Le password non coincidono',
                    formErrors: 'Controlla i campi del modulo'
                },
                errors: {
                    'invalid-token': 'Il link di reimpostazione della password non è valido. Richiedi un nuovo link.',
                    'reset-failed': 'Impossibile reimpostare la password. Riprova.',
                    'server-error': 'Si è verificato un errore imprevisto. Riprova più tardi.'
                },
                success: 'Password cambiata con successo. Reindirizzamento al login...'
            }
        },
        sidebar: {
            menu: {
                explore: 'ESPLORA',
                categories: 'Categorie',
                chats: 'MESSAGGI'
            },
            footer: {
                terms: 'Termini e condizioni',
                rights: '© 2024 Tutti i diritti riservati'
            },
            showMore: 'Mostra di Più',
            showLess: 'Mostra di Meno',
            showMoreCount: 'Mostra di Più ({count})',
            popularTags: 'Tag',
            categories: 'Categorie',
            languages: 'Lingue',
            details_relationship: 'Dettagli della relazione',
            close_profile: "Chiudere il profilo",
            show_profile: "Mostrare il profilo"
        },
        error: {
            404: {
                title: 'Pagina non trovata',
                description: 'La pagina che stai cercando non esiste o è stata spostata.',
                button: 'Torna alla Home'
            }
        },
        subcategories: {
            noResults: 'Nessun risultato trovato',
            tryAnother: 'Prova un\'altra categoria o sottocategoria'
        },
        tags: {
            noResults: 'Nessun agente trovato con questo tag',
            tryAnother: 'Prova un altro tag'
        },
        discover: {
            moreAgents: "Scopri qui più fidanzate virtuali"
        },
        chat: {
            connectedAs: "Connesso come:",
            publicMessage: "Messaggio pubblico...",
            waitMessage: "Attendi {seconds} secondi per inviare un altro messaggio.",
            messageRemoved: "Il messaggio verrà rimosso in {seconds} secondi"
        },
        profileMenu: {
            profile: 'Profilo',
            billding: 'Fatturazione'
        },
        profile: {
            uploadPhoto: "Carica foto",
            sizeLimit: "La dimensione massima di caricamento è 1 MB.",
            editProfile: "Modifica profilo",
            username: "Nome utente",
            birthdate: "Data di nascita",
            phoneIndicator: "Indicatore telefonico",
            phoneNumber: "Numero di telefono",
            gender: "Genere",
            male: "Maschio",
            female: "Femmina",
            other: "Altro",
            email: "Email",
            password: "Password",
            confirmPassword: "Conferma Password",
            saveChanges: "Salva le modifiche"
        },
        billing: {
            subscriptionPlan: "Piano di Abbonamento",
            standard: "Standard",
            monthlyPlan: "Piano Mensile",
            cancelSubscription: "Annulla abbonamento",
            nextPayment: "Il tuo prossimo pagamento è",
            willBeChargedOn: "che sarà addebitato il",
            autoRenewal: "Il pagamento si rinnoverà automaticamente ogni mese"
        },
        paymentMethod: {
            title: "Metodo di Pagamento",
            description: "Scegli il metodo di pagamento che preferisci per i pagamenti futuri.",
            endingIn: "termina in",
            expires: "Scade il",
            primaryCard: "Carta Principale",
            setAsPrimary: "Imposta come Principale",
            edit: "Modifica",
            addNew: "Aggiungi Nuovo Metodo di Pagamento"
        },
        emojichats: {
            message: "Registrati Gratuitamente, per utilizzare questa e altre opzioni "
        },
        errors: {
            sessionNotRecovered: 'Impossibile recuperare la sessione.'
        }
    },
    pt: {
        cookies: {
            cookieTitle: "Somente para Adultos: Personalizamos Seu Prazer",
            cookieWarning: "Utilizamos cookies e tecnologias semelhantes, incluindo cookies de terceiros, para lhe proporcionar uma experiência mais imersiva e personalizada. Estes nos ajudam a otimizar o site, analisar o tráfego, adaptar o conteúdo às suas preferências e oferecer interações mais fluidas. Ao continuar navegando, você aceita o uso de cookies. Para mais informações, consulte nossa ",
            cookiePolicy: "Política de cookies",
            accept: "Aceitar Cookies",
            manage: "Gerenciar ou rejeitar Cookies",
            rejectAll: "Rejeitar todos",
            acceptAll: "Aceitar todos",
            close: "Fechar",
            manageTitle: "Gerencie suas preferências de Cookies",
            manageText: "Selecione suas preferências de cookies.",
            adultWarning: "NovaVirtual contém conteúdo para adultos. Ao utilizar o site, você reconhece que tem mais de 18 anos."
        },
        race: {
            title1: "Latina",
            title2: "Branca",
            title3: "Negra",
            title4: "Asiática",
            title5: "Árabe",
            title6: "Indiana",
            description1: "As latinas são pura paixão, com curvas irresistíveis e um fogo interior que você não conseguirá apagar.",
            description2: "As garotas brancas vão te cativar com sua elegância, beleza angelical e um toque de travessura irresistível.",
            description3: "As mulheres negras exalam sensualidade, energia e um ritmo ardente que vai te fazer perder o controle.",
            description4: "As asiáticas combinam doçura e mistério, com uma sensualidade exótica que vai te deixar sem fôlego.",
            description5: "As mulheres árabes são uma mistura perfeita de charme e misticismo, prontas para liberar seus desejos mais profundos.",
            description6: "As mulheres indianas têm uma beleza hipnotizante e uma sedução envolvente que vai te prender no feitiço delas."
        },
        body: {
            title1: "Magra",
            title2: "Atlética",
            title3: "Curvilínea",
            title4: "Gordita",
            description1: "As mulheres magras são uma combinação de charme e sensualidade, com corpos esbeltos que convidam à exploração.",
            description2: "As mulheres atléticas têm a mistura perfeita de força e feminilidade, prontas para te levar a um jogo sem limites.",
            description3: "As mulheres curvilíneas sabem usar seu corpo para te seduzir, com movimentos que vão te deixar sem palavras.",
            description4: "As mulheres voluptuosas são puro fogo e doçura, com uma sensualidade envolvente que vai te fazer querer mais."
        },
        height: {
            description1: "Pequenas, mas irresistíveis, com uma sensualidade que desafia sua altura e uma atitude travessa que vai te encantar.",
            description2: "A altura perfeita para aproveitar cada momento, com um equilíbrio entre elegância e paixão que vai te prender.",
            description3: "Altas e sedutoras, com pernas de tirar o fôlego e uma presença que vai te fazer desejar cada segundo ao lado delas.",
            description4: "Imponentes e cheias de magnetismo, com uma sensualidade dominante e um corpo feito para o prazer."
        },
        hair: {
            title1: "Preto",
            title2: "Castanho",
            title3: "Loiro",
            title4: "Ruivo",
            title5: "Outra cor",
            description1: "Cabelos negros tão profundos quanto a noite, misteriosos e envolventes, prontos para te enfeitiçar com seu charme irresistível.",
            description2: "As morenas combinam doçura e travessura, com uma sensualidade natural que vai te cativar instantaneamente.",
            description3: "As loiras atraem todos os olhares, com um toque de ousadia que as torna irresistíveis.",
            description4: "As ruivas ardem em paixão, com um fogo interior que vai te enlouquecer a cada encontro.",
            description5: "Ousadas e únicas, com um visual fora do comum que reflete uma personalidade intensa e incomparável."
        },        
        age: {
            title1: "Madura",
            description1: "Curiosidade e paixão se unem em uma aventura cheia de descobertas.",
            description2: "Colegiais, universitárias e esposas ousadas prontas para realizar suas fantasias mais ardentes.",
            description3: "As MILFs de 30 a 40 anos têm a confiança e a experiência para levá-lo ao auge do prazer.",
            description4: "Acima dos 40, sedutoras e sem inibições, prontas para mostrar tudo o que aprenderam."
        },
        alert: {
            title: 'Namoradas Virtuais Online',
            description: 'Converse com namoradas virtuais ao vivo na NovaVirtual.com. Conexões íntimas e personalizadas com as garotas mais populares. Converse agora!'
        },
        agent: {
            status: 'ONLINE',
            addFavorite: 'Adicionar às favoritas',
            removeFavorite: 'Remover das favoritas',
            privateChat: "VAMOS CONVERSAR EM PRIVADO",
            viewGallery: "Ver galeria",
            gallery: {
                noImages: "Nenhuma imagem encontrada.",
                image: "Imagem da galeria"
            },
            sendGift: "Enviar presente",
            buyCredits: "Comprar créditos"
        },
        home: {
            h1: 'Conecte-se Com Sua <span class="highlight-text">Namorada Virtual</span>',
            description: 'Desfrute de momentos únicos com sua companheira ideal. <br>Converse, conecte-se e viva experiências inesquecíveis de qualquer <br>lugar, mantendo a emoção e a companhia sempre com você.',
        },
        header: {
            login: 'ENTRAR',
            register: 'REGISTRAR',
            access: 'ACESSO',
            searchLanguage: 'Buscar idioma',
            explore: 'EXPLORAR',
            chats: 'MENSAGENS',
            logout: 'Sair',
            messages: 'Mensagens',
            profile: 'Perfil',
            myFavorites: 'Minhas Favoritas',
        },
        auth: {
            register: {
                title: 'Criar uma conta',
                description: 'Digite seu email e senha para criar\numa nova conta.',
                email: 'Email',
                password: 'Senha',
                confirmPassword: 'Confirmar senha',
                button: 'CRIAR CONTA',
                haveAccount: 'Já tem uma conta?',
                loginHere: 'Entre aqui!',
                validation: {
                    emailRequired: 'O email é obrigatório',
                    emailValid: 'O email deve ser válido',
                    passwordRequired: 'A senha é obrigatória',
                    passwordLength: 'A senha deve ter pelo menos 8 caracteres',
                    confirmRequired: 'Confirme sua senha',
                    passwordsMatch: 'As senhas não coincidem',
                    formErrors: 'Por favor, corrija os erros no formulário'
                },
                success: 'Registro bem-sucedido!',
                errors: {
                    'email-taken': 'Este email já está registrado',
                    'password-length': 'A senha deve ter pelo menos 8 caracteres',
                    'password-confirmed': 'As senhas não coincidem'
                }
            },
            login: {
                title: 'Entrar',
                description: 'Digite seu email e senha para acessar.',
                email: 'Email',
                password: 'Senha',
                forgotPassword: 'Esqueceu a senha?',
                button: 'ENTRAR',
                noAccount: 'Ainda não tem uma conta?',
                registerHere: 'Crie sua conta aqui!',
                validation: {
                    emailRequired: 'O email é obrigatório',
                    emailValid: 'O email deve ser válido',
                    passwordRequired: 'A senha é obrigatória',
                    formErrors: 'Por favor, corrija os erros no formulário'
                },
                success: 'Login realizado com sucesso!',
                errors: {
                    'invalid-credentials': 'Credenciais inválidas',
                    'email-not-found': 'Usuário não encontrado',
                    'invalid-password': 'Senha incorreta',
                    'account-disabled': 'Conta desativada'
                }
            },
            resetPassword: {
                title: 'Recuperar Senha',
                description: 'Digite seu email para receber instruções.',
                email: 'Email',
                button: 'ENVIAR INSTRUÇÕES',
                rememberPassword: 'Lembrou sua senha?',
                backToLogin: 'Voltar ao login!',
                validation: {
                    emailRequired: 'O email é obrigatório',
                    emailValid: 'O email deve ser válido',
                    formErrors: 'Verifique os campos do formulário'
                },
                success: 'Instruções enviadas para seu email',
                errors: {
                    'email-not-found': 'Este endereço de email não está registrado.',
                    'too-many-requests': 'Muitas solicitações de redefinição de senha. Tente novamente mais tarde.',
                    'cannot-send-link': 'Não foi possível enviar o link de redefinição de senha. Verifique seu email ou tente mais tarde.',
                    'server-error': 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
                }
            },
            changePassword: {
                title: 'Criar Nova Senha',
                description: 'Digite uma nova senha para sua conta',
                password: 'Nova Senha',
                confirmPassword: 'Confirmar Nova Senha',
                button: 'ALTERAR SENHA',
                validation: {
                    passwordRequired: 'A senha é obrigatória',
                    passwordLength: 'A senha deve ter pelo menos 8 caracteres',
                    confirmRequired: 'Por favor confirme sua senha',
                    passwordsMatch: 'As senhas não coincidem',
                    formErrors: 'Verifique os campos do formulário'
                },
                errors: {
                    'invalid-token': 'O link de redefinição de senha é inválido. Solicite um novo link.',
                    'reset-failed': 'Não foi possível redefinir a senha. Tente novamente.',
                    'server-error': 'Ocorreu um erro inesperado. Tente novamente mais tarde.'
                },
                success: 'Senha alterada com sucesso. Redirecionando para o login...'
            }
        },
        sidebar: {
            menu: {
                explore: 'EXPLORAR',
                categories: 'Categorias',
                chats: 'MENSAGENS'
            },
            footer: {
                terms: 'Termos e condições',
                rights: '© 2024 Todos os direitos reservados'
            },
            showMore: 'Ver Mais',
            showLess: 'Ver Menos',
            showMoreCount: 'Ver Mais ({count})',
            popularTags: 'Tags',
            categories: 'Categorias',
            languages: 'Idiomas',
            details_relationship: 'Detalhes do relacionamento',
            close_profile: "Fechar perfil",
            show_profile: "Mostrar perfil"
        },
        error: {
            404: {
                title: 'Página não encontrada',
                description: 'A página que você está procurando não existe ou foi movida.',
                button: 'Voltar para o Início'
            }
        },
        subcategories: {
            noResults: 'Nenhum resultado encontrado',
            tryAnother: 'Tente outra categoria ou subcategoria'
        },
        tags: {
            noResults: 'Nenhum agente encontrado com esta tag',
            tryAnother: 'Tente outra tag'
        },
        discover: {
            moreAgents: "Descubra mais namoradas virtuais aqui"
        },
        chat: {
            connectedAs: "Conectado como:",
            publicMessage: "Mensagem pública...",
            waitMessage: "Aguarde {seconds} segundos para enviar outra mensagem.",
            messageRemoved: "A mensagem será removida em {seconds} segundos"
        },
        profileMenu: {
            profile: 'Perfil',
            billding: 'Faturamento'
        },
        profile: {
            uploadPhoto: "Carregar foto",
            sizeLimit: "O tamanho máximo de upload é 1 MB.",
            editProfile: "Editar perfil",
            username: "Nome de usuário",
            birthdate: "Data de nascimento",
            phoneIndicator: "Indicador de telefone",
            phoneNumber: "Número de telefone",
            gender: "Gênero",
            male: "Masculino",
            female: "Feminino",
            other: "Outro",
            email: "Email",
            password: "Senha",
            confirmPassword: "Confirmar senha",
            saveChanges: "Salvar alterações"
        },
        billing: {
            subscriptionPlan: "Plano de Assinatura",
            standard: "Padrão",
            monthlyPlan: "Plano Mensal",
            cancelSubscription: "Cancelar assinatura",
            nextPayment: "Seu próximo pagamento é",
            willBeChargedOn: "que será cobrado em",
            autoRenewal: "O pagamento será renovado automaticamente todos os meses"
        },
        paymentMethod: {
            title: "Método de Pagamento",
            description: "Escolha o método de pagamento que preferir para futuros pagamentos.",
            endingIn: "terminando em",
            expires: "Expira em",
            primaryCard: "Cartão Principal",
            setAsPrimary: "Definir como Principal",
            edit: "Editar",
            addNew: "Adicionar Novo Método de Pagamento"
        },
        emojichats: {
            message: "Registre-se Gratuitamente, para usar esta e mais opções "
        },
        errors: {
            sessionNotRecovered: 'Não foi possível recuperar a sessão.'
        }
    }
};

export default messages;




