import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import router from './router';
import axios from '@/plugins/axios';
import { createI18n } from 'vue-i18n';
import messages from './i18n/messages';
import { updateChecker } from './mixins/updateChecker';
import moshaToast from 'mosha-vue-toastify'
import 'mosha-vue-toastify/dist/style.css'
import { useAuthStore } from '@/stores/auth';
import { createHead } from '@vueuse/head'

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || navigator.language.split('-')[0] || 'en',
    fallbackLocale: 'en',
    messages,
});

const app = createApp(App);
const head = createHead()
app.use(head)
app.use(createPinia());
app.use(vuetify);
app.use(moshaToast);
app.use(router);
app.use(i18n);
app.mixin(updateChecker);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$apiUrl = process.env.VUE_APP_URL_PROD;
//app.config.globalProperties.$apiUrl = process.env.VUE_APP_URL_DEV;

app.mount('#app');

// Crear usuario temporal si no hay usuario logueado
const authStore = useAuthStore();
if (!authStore.checkAuth()) {
    authStore.setVisitor();
}

// Remover loading screen cuando Vue esté listo
window.addEventListener('load', () => {
  const loadingScreen = document.getElementById('loading-screen')
  if (loadingScreen) {
    loadingScreen.style.opacity = '0'
    setTimeout(() => {
      loadingScreen.style.display = 'none'
    }, 300)
  }
})