export const updateChecker = {
    data() {
        return {
            currentBuildVersion: process.env.VUE_APP_BUILD_VERSION,
            lastCheck: parseInt(localStorage.getItem('lastUpdateCheck')) || 0,
            cachedVersion: localStorage.getItem('buildVersion')
        }
    },
    created() {
        this.checkForUpdates();
    },
    methods: {
        async checkForUpdates() {
            const now = Date.now();

            // Verificar tiempo transcurrido desde última revisión
            if (now - this.lastCheck < 3000) {
                return;
            }

            // Actualizar timestamp de última verificación
            localStorage.setItem('lastUpdateCheck', now);
            this.lastCheck = now;

            try {
                const response = await fetch('/version.json?t=' + now);
                const data = await response.json();

                // Guardar nueva versión en cache
                localStorage.setItem('buildVersion', data.version);
                this.cachedVersion = data.version;

                // Comparar con versión actual
                if (this.cachedVersion !== this.currentBuildVersion) {
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error verificando actualización:', error);
            }
        }
    }
}