<template>
    <div v-if="!cookieStore.accepted" class="cookie-overlay">
      <div class="cookie-wrapper">
        <div class="adult-warning">
          <img src="../../assets/icons/18.png" alt="Logo" class="logo">
          <span>{{ $t('cookies.adultWarning') }}</span>
        </div>
        <div class="cookie-container">
          <h2>{{ $t('cookies.cookieTitle') }}</h2>
          <p class="cookie-text">
            {{ $t('cookies.cookieWarning') }}
            <a href="#" class="cookie-link">{{ $t('cookies.cookiePolicy') }}</a>.
          </p>
          <button @click="acceptCookies" class="accept-btn">{{ $t('cookies.accept') }}</button>
          <button @click="manageCookies" class="manage-btn">{{ $t('cookies.manage') }}</button>
        </div>
      </div>
    </div>

      <!-- Modal de gestión de cookies -->
    <div v-if="isManagingCookies" class="cookie-management-modal">
      <div class="modal-content">
        <h3>{{ $t('cookies.manageTitle') }}</h3>
        <p>{{ $t('cookies.manageText') }}</p>
        <div class="modal-buttons">
          <button @click="rejectCookies">{{ $t('cookies.rejectAll') }}</button>
          <button @click="acceptAllCookies">{{ $t('cookies.acceptAll') }}</button>
          <button @click="closeModal">{{ $t('cookies.close') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useCookieStore } from '@/stores/cookie';
  
  const cookieStore = useCookieStore();
  const isManagingCookies = ref(false);

  const acceptCookies = () => {
    cookieStore.acceptCookies();
  };

  const manageCookies = () => {
    isManagingCookies.value = true; 
    console.log("Modal visible: ", isManagingCookies.value); // Para depuración
  };

  const closeModal = () => {
    isManagingCookies.value = false; 
  };

  const acceptAllCookies = () => {
    cookieStore.acceptCookies();
    closeModal();
  };

  const rejectCookies = () => {
    cookieStore.rejectCookies(); 
    closeModal();
  };

  </script>
  
  <style scoped>
  .cookie-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(80, 0, 0, 1); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .cookie-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .cookie-container {
      position: relative;
      background: url('../../assets/images/image.png') no-repeat center center;
      background-size: cover;
      padding: 180px 120px 30px 120px;
      text-align: center;
      align-items: center;
      border-radius: 8px;
      max-width: 800px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
  }

  .cookie-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(53, 17, 17, 0.7); /* Color con opacidad para el difuminado */
    border-radius: 8px;
    z-index: 0;
  }

  .cookie-link{
    color: #FEC400;
    text-decoration: none;
  }

  .cookie-container > * {
    position: relative;
    z-index: 1;
    opacity: 1; /* Asegurar que el contenido sea totalmente visible */
  }
  
  button {
    padding: 10px 20px;
    background: linear-gradient(135deg, #FEC400 0%, #F98B06 100%);
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
  }

  .accept-btn {
    margin-top: 20px;
    width: auto;
    font-weight: bold;
    text-align: center;
    display: block;
    padding: 10px 20px;
    background: linear-gradient(135deg, #FEC400 0%, #F98B06 100%);
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
  }

  .modal-buttons {
    padding-top: 20px;
    display: flex;
    flex-direction: column; /* Coloca los botones en una columna */
    gap: 5px; /* Espacio entre los botones */
    align-items: center;
  }

  .modal-buttons button {
    padding: 10px 20px;
    background: linear-gradient(135deg, #FEC400 0%, #F98B06 100%);
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    font-size: 15px;
    margin: 1px 0; /* Espacio vertical entre los botones */
    width: 150px;
  }

  .cookie-text{
    font-size: 15px; 
    text-align: center;
    line-height: 1.5;
  }

  .adult-warning {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 8px; /* Espacio entre el logo y el texto */
  font-size: 15px;
  color: #ed7a7a;
  padding: 10px;
  border-radius: 5px;
}

.adult-warning .logo {
  height: 2em; /* Hace que el logo tenga el tamaño de la letra */
  width: auto;
}

.manage-btn {
  background: none;
  color: #FEC400;
  padding: 12px 18px;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-management-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(80, 0, 0, 0.7); /* Fondo semitransparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-content {
  background: rgba(99, 0, 0, 1);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  width: 400px;
  color: white;
  font-size: 15px;
}


  @media (max-width: 600px) {
  .cookie-container {
    max-width: 90%;
    padding: 20px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
  }

  button {
    font-size: 14px;
    padding: 10px 16px;
  }
}
  </style>
  