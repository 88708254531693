<template>
  <CookieConsent />
  <router-view />
</template>

<script>
import CookieConsent from '@/components/partials/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    CookieConsent, 
  },
};
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --font-family-base: 'Roboto', sans-serif;
}

* {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: #d02120;
}

::selection {
  color: white;
  background: #d02120;
}


body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #454545;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #888888;
}

@media (min-width: 1920px) {
    .v-container {
        max-width: 1200px !important;
    }
}

.font-thin {
  font-weight: 100;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}
</style>